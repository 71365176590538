.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(17deg);
  }
}

/* Navbar */

.navbar {
  padding-bottom: 1px;
  border-bottom: solid 1px #e8e8e8;
  background-color: white;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
    0 6px 16px -11px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;

  z-index: 99990;
}

.nav-header {
  background-color: white;
  border-bottom: 0;
  padding: 0 4px 0 24px;
}

.logo {
  width: 200px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.navbar-menu {
  width: calc(100% - 200px);
  float: left;
}
.navbar-menu .ant-layout-header {
  padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: left;
}

.navbar-menu .rightMenu {
  float: right;
}

.menuButton {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 14px;
  display: none;
  background: none;
  margin-right: 10px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.username {
  display: none;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -10px;
  }

  .ant-drawer-title > .brand-font {
    margin-bottom: 0;
  }

  .username {
    display: inline-block;
  }
}

/* Navbar End */

/* RJ QR CODE  */

.App {
  text-align: center;
  font-family: Lato, sans-serif;

  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(158, 219, 42);
  opacity: 0.9;
  z-index: -1; /* Ensure the background overlay is behind the content */
}

.App-header {
  background-image: url("/src/images/qr-code-cover.jpg");
  background-size: cover;
  background-position: center;
  height: 288px;
  max-width: 543px;
  width: 100%;
  color: white;
  position: relative;
  margin: 0px 0;
  z-index: 1; /* Ensure the banner is above the overlay */
}

.profile-container {
  position: relative;
  display: inline-block;
  text-align: center;
  z-index: 2; /* Ensure the profile picture is above the overlay */
}

.imageBgGradient {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -1px;
  height: 182px;
  background: linear-gradient(rgba(158, 219, 42, 0) 0%, rgb(158, 219, 42) 100%);
  z-index: -1; /* Ensure this gradient is behind the profile picture */
}
.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid white;
  position: absolute;
  top: calc(80% - 20px); /* Center the profile picture vertically */
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-header h1 {
  margin-top: 80px; /* Adjust based on your image size */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
}
.icon-container {
  display: flex;
  flex-direction: row; /* Align icons side by side */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.icon {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: yellow; /* Circle background color */
  border-radius: 50%; /* Make the background circular */
  margin: 0 10px; /* Space between icons */
  font-size: 24px;
  color: black; /* Icon color */
  text-decoration: none;
}
.social-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
/* RJ QR CODE END */
